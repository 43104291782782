<!-- 高级搜索区域 -->
<template>
  <div>
    <a-form ref="searchForm" :model="searchParam">
      <a-row>
        <a-col :span="8">
          <a-form-item
            label="选择账户:"
            name="accountId"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-select
              v-model:value="searchParam.accountId"
              placeholder="请选择一个账户"
            >
              <a-select-option :value="0">所有</a-select-option>
              <a-select-option
                v-for="(item, index) in accountInfoList"
                :key="index"
                :value="item.uid"
                >{{ item.uname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item
            label="选择接口:"
            name="apiId"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-select
              v-model:value="searchParam.apiId"
              placeholder="请选择一个接口"
            >
              <a-select-option :value="0">所有</a-select-option>
              <a-select-option
                v-for="(item, index) in interfaceInfoList"
                :key="index"
                :value="item.id"
                >{{ item.apiName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4" :offset="1">
          <a-button type="primary" @click="doSearch">
            <SearchOutlined />搜索
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script lang='js'>
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, reactive, toRefs, onMounted, defineComponent } from "vue";
import { getInterfaceList } from "@/api/application/interface";
import { getAccountInfoList } from "@/api/account/account";
export default defineComponent({
  name: 'SearchArea',
  components: {
    SearchOutlined
  },
  setup(props,context) {
    const searchForm = ref();
    const state = reactive({
      interfaceInfoList: [],
      accountInfoList: [],
      searchParam:{
        pageIndex:1,
        pageSize:10,
        accountId: 0,
        apiId: 0
      },
      formLayout2X2:{
        labelCol: {
              span: 4,
            },
            wrapperCol: {
              span: 20,
            },
      }
    });
    onMounted(() => {
      initInterfaceInfoList();
      initAccountInfoList();
    });
    const initInterfaceInfoList = () => {
      getInterfaceList().then((resp) => {
        if (resp) {
          state.interfaceInfoList = resp;
        }
      });
    };
    const initAccountInfoList = () => {
      getAccountInfoList().then((resp) => {
        if (resp) {
          state.accountInfoList = resp;
        }
      });
    };
    const doSearch = () =>{
      context.emit("initInterfaceHistoryList",state.searchParam);
    }
    const refData = toRefs(state);
    return {
        ...refData,
        initInterfaceInfoList,
        initAccountInfoList,
        searchForm,
        doSearch
    }
  }
  });
</script>
<style lang='scss' scoped>
.ant-form-item {
  margin-bottom: 0px;
}
</style>